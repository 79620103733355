import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from "@angular/core";
import {
  Notification,
  NotificationCenterItem
} from "app/channels/models/notification.model";
import { ChannelRepository } from "app/channels/repository/channel.repository";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { getIsAppBootstrapped } from "app/reducers";
import { TalkRootState } from "app/talk/reducers";
import { TranslateService } from "@ngx-translate/core";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { filter, distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { CommonService } from "app/shared/providers";
import { Broadcaster } from "app/talk/shared/providers/broadcaster.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ConfigService } from "app/config.service";
import { NotificationStatus } from "app/channels/models/notification-status.model";
import { VNCCommonDialogComponent } from "../common-dialog/common-dialog.component";
import { MatDialog } from "@angular/material/dialog";

export enum CenterType {
  "NOTIFICATIONS" = "notifications",
  "SUBSCRIPTIONS" = "subscriptions"
}

export enum NotificationCategory {
  "ALL" = "all",
  "UNREAD" = "unread"
}

@Component({
  selector: "vp-notification-center",
  templateUrl: "./notification-center.component.html",
  styleUrls: ["./notification-center.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  view: any = CenterType.NOTIFICATIONS;
  tabs = [
    {
      label: "ALL_NOTIFICATIONS",
      value: "all",
      active: false
    },
    {
      label: "UNREAD_LBL",
      value: "unread",
      active: false
    }
  ];
  gotNotifications: boolean = false;
  notificationCategory = NotificationCategory;
  allNotificationInfo: { loading: boolean; loaded: boolean } = {
    loading: false,
    loaded: true
  };
  unreadNotificationInfo: { loading: boolean; loaded: boolean } = {
    loading: false,
    loaded: true
  };
  allNotifications: Notification[] = [];
  unreadNotifications: Notification[] = [];
  allNotificationItems: NotificationCenterItem[] = [];
  unreadNotificationItems: NotificationCenterItem[] = [];
  private isAlive$ = new Subject<boolean>();
  @Output() close = new EventEmitter();
  allNotificationsGroupedByDate = {};
  allPeriodGroup = [];
  unreadNotificationsGroupedByDate = {};
  unreadPeriodGroup = [];
  notificationCount: number = 0;
  headerInfo = {
    today: {
      label: "TODAY",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    yesterday: {
      label: "YESTERDAY",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    this_week: {
      label: "THIS_WEEK",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    last_week: {
      label: "LAST_WEEK",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    this_month: {
      label: "THIS_MONTH",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    last_month: {
      label: "LAST_MONTH",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    this_year: {
      label: "THIS_YEAR",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    },
    last_year: {
      label: "LAST_YEAR",
      isExapanded: true,
      isShowMore: false,
      isDisabled: false
    }
  };
  isMobileScreen: boolean = false;
  bootstrapped: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private channelRepository: ChannelRepository,
    private translate: TranslateService,
    private channelSnackBarService: ChannelSnackbarService,
    private commonService: CommonService,
    private store: Store<TalkRootState>,
    private broadcaster: Broadcaster,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService,
    private dialog: MatDialog
  ) {
    this.isMobileScreen =
      this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnInit(): void {
    let selectedNotificationType = localStorage.getItem(
      "selectedNotificationType"
    );
    if (selectedNotificationType) {
      this.activateTab(selectedNotificationType);
      this.changeDetectorRef.markForCheck();
    } else {
      this.activateTab(this.tabs[0].value);
      this.changeDetectorRef.markForCheck();
    }
    this.broadcaster
      .on<boolean>("CLICK_CHANNEL_NOTIFICATION")
      .pipe(takeUntil(this.isAlive$))
      .subscribe((notification) => {
        this.clickOnNotificationItem(notification);
      });
    // console.warn("[notification-center][ngOnInit] getting notifications");
    this.store
      .select(getIsAppBootstrapped)
      .pipe(distinctUntilChanged())
      .subscribe((v) => {
        if (!this.bootstrapped) {
          if (!!v) {
            this.bootstrapped = true;
            setTimeout(() => {
              this.getAllNotifications();
              this.channelRepository
                .selectAllNotifications()
                .pipe(takeUntil(this.isAlive$))
                .pipe(
                  filter((v) => !!v),
                  distinctUntilChanged(
                    (p: Notification[], q: Notification[]) =>
                      p.length === q.length &&
                      p
                        .map((c) => c.read + c.read_at + c.defaultImageIndex)
                        .toString() ===
                        q
                          .map((c) => c.read + c.read_at + c.defaultImageIndex)
                          .toString()
                  )
                )
                .subscribe((notifications) => {
                  this.allNotifications = notifications;
                  this.convertToAllNotificationItemsGroupByDate(
                    this.allNotifications
                  );
                  this.allPeriodGroup.forEach((periodGroup) => {
                    this.checkDisableMarkAsReadNotification(periodGroup);
                  });
                  this.changeDetectorRef.markForCheck();
                });

              this.channelRepository
                .selectUnreadNotifications()
                .pipe(takeUntil(this.isAlive$))
                .pipe(
                  filter((v) => !!v),
                  distinctUntilChanged(
                    (p: Notification[], q: Notification[]) =>
                      p.length === q.length &&
                      p
                        .map((c) => c.read + c.read_at + c.defaultImageIndex)
                        .toString() ===
                        q
                          .map((c) => c.read + c.read_at + c.defaultImageIndex)
                          .toString()
                  )
                )
                .subscribe((notifications) => {
                  setTimeout(() => {
                    this.gotNotifications = true;
                  }, 2000);
                  this.unreadNotifications = notifications;
                  this.convertToUnreadNotificationItemsGroupByDate(
                    this.unreadNotifications
                  );
                  this.changeDetectorRef.markForCheck();
                });

              this.channelRepository
                .getUnreadNotificationCountStore()
                .pipe(takeUntil(this.isAlive$))
                .subscribe((count) => {
                  this.notificationCount = count;
                  this.changeDetectorRef.markForCheck();
                });

              this.commonService.currentLanguage
                .pipe(takeUntil(this.isAlive$))
                .subscribe(() => {
                  this.convertToAllNotificationItemsGroupByDate(
                    this.allNotifications
                  );
                  this.convertToUnreadNotificationItemsGroupByDate(
                    this.unreadNotifications
                  );
                  setTimeout(() => this.changeDetectorRef.markForCheck(), 200);
                });
            }, 3000);
          }
        }
      });

    this.broadcaster
      .on<any>("NOTIFICATION_PANEL_CLICK")
      .pipe(takeUntil(this.isAlive$))
      .subscribe((notification) => {
        this.notificationPanelClick(notification);
      });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  activateTab(tabName: string) {
    this.tabs.map((tab) => {
      tab.active = tab.value === tabName;
    });
    localStorage.setItem("selectedNotificationType", tabName);
  }

  getActiveTab(): string {
    const activeTab = this.tabs.find((tab) => !!tab.active);
    return activeTab.value;
  }

  clickOnNotificationItem(notification) {
    if (!notification?.read) {
      this.channelRepository.markAsReadNotification(notification);
    }
    this.close.emit();
    if (this.isMobileScreen) {
      setTimeout(() => {
        if (
          notification.notification_type &&
          (notification.notification_type ===
            NotificationStatus.SOCIAL_POST_CREATED ||
            notification.notification_type ===
            NotificationStatus.SOCIAL_POST_REPLY_CREATED)
        ) {
          return;
        }
        this.broadcaster.broadcast("showRightPanel");
      }, 100);
    }
    if (notification?.data?.channel?.id) {
      if (!notification?.data?.channel?.subscribed && notification?.data?.channel?.channel_type == "private") {
        this.dialog.open(VNCCommonDialogComponent, {
          width: "480px",
          height: "220px",
          maxHeight: "95%",
          backdropClass: "vnctalk-form-backdrop",
          panelClass: "vnctalk-form-panel",
          disableClose: true,
          autoFocus: true,
          data: {
            headerTranslationKey: "INFO",
            bodyTranslationKey: "NOTIFICATION_NO_ACCESS_TO_CHANNEL",
            hideAction: true,
            cancelTranslationKey: "CLOSE"
          }
        });
      } else {
        this.channelRepository.redirectToObjectfromNotification(notification);
      }
    } else {
      this.channelRepository.redirectToObjectfromNotification(notification);
    }
  }

  notificationPanelClick($event) {
    if ($event === "list") {
      this.view = CenterType.SUBSCRIPTIONS;
      this.changeDetectorRef.markForCheck();
    } else if ($event === "setting") {
      this.broadcaster.broadcast("OPEN_NOTIFICATION_DIALOG");
    } else {
      this.underDevelopment();
    }
  }

  backToNotification() {
    this.view = CenterType.NOTIFICATIONS;
  }

  onUserScroll(event, tabType: string) {
    this.loadMoreNotifications(tabType);
  }

  private loadMoreNotifications(tabType: string) {
    let offset = 0;
    if (tabType === this.notificationCategory.ALL) {
      offset = this.allNotifications.length;
      this.channelRepository.getMoreNotifications(offset);
    }

    if (tabType === this.notificationCategory.UNREAD) {
      offset = this.unreadNotifications.length;
      this.channelRepository.getMoreUnreadNotifications(offset);
    }
  }

  underDevelopment() {
    this.translate
      .get("UNDER_DEVELOPMENT")
      .pipe(take(1))
      .subscribe((text) => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
  }

  convertToAllNotificationItemsGroupByDate(notifications: Notification[]) {
    this.allNotificationsGroupedByDate = {};
    this.allPeriodGroup = [];
    const notificationData = {};
    if (notifications && notifications?.length > 0) {
      notifications.forEach((notificationObj) => {
        let dateText = notificationObj?.period_type;
        if (!this.allPeriodGroup.includes(dateText)) {
          this.allPeriodGroup.push(dateText);
          notificationData[dateText] = {
            notifications: []
          };
        }
        this.channelRepository
          .getNotificationCenterItem(notificationObj)
          .then((notificationItem) => {
            if (notificationItem) {
              if (
                notificationItem.isSocial &&
                (notificationItem.object.data?.comment?.topic_is_social ||
                  notificationItem.object.data?.topic?.is_social)
              ) {
                /** TODO add any code for social restriction */
              } else {
                notificationData?.[dateText].notifications.push(
                  notificationItem
                );
                this.changeDetectorRef.markForCheck();
              }
            }
          });
      });
      this.allNotificationsGroupedByDate = notificationData;
    }
  }

  convertToUnreadNotificationItemsGroupByDate(notifications: Notification[]) {
    this.unreadNotificationsGroupedByDate = {};
    this.unreadPeriodGroup = [];
    const notificationData = {};
    if (notifications && notifications?.length > 0) {
      notifications.forEach((notificationObj) => {
        let dateText = notificationObj?.period_type;
        if (!this.unreadPeriodGroup.includes(dateText)) {
          this.unreadPeriodGroup.push(dateText);
          notificationData[dateText] = {
            notifications: []
          };
        }
        this.channelRepository
          .getNotificationCenterItem(notificationObj)
          .then((notificationItem) => {
            if (notificationItem) {
              notificationData?.[dateText].notifications.push(notificationItem);
              this.changeDetectorRef.markForCheck();
            }
          });
        this.unreadNotificationsGroupedByDate = notificationData;
      });
    }
  }

  getHeaderLabel(period_type) {
    return this.headerInfo[period_type]?.label;
  }

  showHideNotifications(period_type) {
    this.headerInfo[period_type].isExapanded =
      !this.headerInfo[period_type].isExapanded;
  }

  showMoreNotifications(period_type) {
    this.headerInfo[period_type].isShowMore =
      !this.headerInfo[period_type].isShowMore;
  }

  markAsReadNotificaitons(period_type) {
    this.channelRepository.markAsReadNotifications(period_type);
    this.checkDisableMarkAsReadNotification(period_type);
  }

  markAsReadAllNotificaitons() {
    this.channelRepository.markAsReadAllNotifications();
    this.allPeriodGroup.forEach((periodGroup) => {
      this.checkDisableMarkAsReadNotification(periodGroup);
    });
  }

  checkDisableMarkAsReadNotification(period_type) {
    if (this.allNotifications) {
      let notifications = this.allNotifications?.filter(
        (n) => !n.read && n.period_type === period_type
      );
      if (!!period_type && !!this.headerInfo[period_type]) {
        if (notifications && notifications.length > 0) {
          this.headerInfo[period_type].isDisabled = false;
          this.changeDetectorRef.markForCheck();
        } else {
          this.headerInfo[period_type].isDisabled = true;
          this.changeDetectorRef.markForCheck();
        }
      }
    } else {
      if (!!period_type && !!this.headerInfo[period_type]) {
        this.headerInfo[period_type].isDisabled = true;
        this.changeDetectorRef.markForCheck();
      };
    }
  }

  getAllNotifications(): void {
    this.configService
      .getLoadedConfig()
      .pipe(
        filter((res) => !!res && res?.isChannelsEnabled !== undefined),
        take(1)
      )
      .subscribe(() => {
        if (this.configService.get("isChannelsEnabled")) {
          this.channelRepository
            .getNotificationInfo()
            .pipe(take(1))
            .subscribe((notificatioInfo) => {
              if (!notificatioInfo?.isLoaded) {
                // this.channelRepository.getUnreadNotificationCount();
                this.channelRepository.getAllNotifications();
                this.channelRepository.getUnreadNotifications();
              }
            });
          this.channelRepository
            .getSubscribedChannelsInfo()
            .pipe(take(1))
            .pipe(filter((v) => !!v))
            .subscribe((info) => {
              if (!info?.isLoaded) {
                this.channelRepository.loadSubscribedChannels();
              }
            });
          this.channelRepository
            .getAllSubscribeTopicInfo()
            .pipe(take(1))
            .subscribe((info) => {
              if (!info?.isLoaded) {
                this.channelRepository.getSubscribeTopicList(0, 25, "");
              }
            });
        }
      });
  }
}
